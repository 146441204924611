

















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'
import axios from 'axios'
import router from '../router'

export interface Order {
  id: number | null;
  aConnector: string | null;
  aPolishing: string | null;
  mode: string | null;
  lineCount: string | null;
  outerDiameter: string | null;
  length: string | null;
  others: string | null;
  bConnector: string | null;
  bPolishing: string | null;
  orderValue: number | null;
}

export class Opt {
  public aConnector: string;
  public aConnectorOverlay: boolean;
  public aPolishing: string;
  public mode: string;
  public modeOverlay: boolean;
  public lineCount: string;
  public outerDiameter: string;
  public length: string;
  public others: Array<string>;
  public bConnector: string;
  public bConnectorOverlay: boolean;
  public bPolishing: string;
  constructor () {
    this.aConnector = ''
    this.aConnectorOverlay = false
    this.aPolishing = ''
    this.mode = ''
    this.modeOverlay = false
    this.lineCount = ''
    this.outerDiameter = ''
    this.length = ''
    this.others = []
    this.bConnector = ''
    this.bConnectorOverlay = false
    this.bPolishing = ''
  }
}

export class OrderForm {
  public company: string | null;
  public name: string | null;
  public email: string | null;
  public tel: string | null;
  public fax: string | null;
  public free: string | null;
  public parts: string | null;
  public act: number | null;
  constructor () {
    this.company = null
    this.name = null
    this.email = null
    this.tel = null
    this.fax = null
    this.free = null
    this.parts = ''
    this.act = 3
  }
}

export default Vue.extend({
  name: 'OptCustomSearch',
  data: function () {
    return {
      loading: false,
      dialog: false,
      message: '',
      dialog_error: false,
      errorMessage: '',
      code: new Opt(),
      orderForm: new OrderForm(),
      emailRules: [
        (value: string) => !!value || 'メールは必須項目です。',
        (value: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || '正しいメールアドレスの書式で入力してください。'
      ],
      companyRules: [
        (value: string) => !!value || '会社名は必須項目です。'
      ],
      nameRules: [
        (value: string) => !!value || '担当者名は必須項目です。'
      ],
      isValid: true,
      headers: [
        { text: '', value: 'id', sortable: false },
        { text: 'A端コネクタ', value: 'aConnector' },
        { text: 'A端研磨方法', value: 'aPolishing' },
        { text: 'B端コネクタ', value: 'bConnector' },
        { text: 'B端研磨方法', value: 'bPolishing' },
        { text: 'モード', value: 'mode' },
        { text: '心数', value: 'lineCount' },
        { text: '外径', value: 'outerDiameter' },
        { text: '長さ', value: 'length' },
        { text: '備考', value: 'others' },
        { text: '注文数', value: 'value' },
        { text: '', value: 'action', sortable: false, align: 'end' }
      ],
      orders: new Array<Order>()
    }
  },
  computed: {
    form (): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean }
    }
  },
  methods: {
    deleteOrder (deleteId: number) {
      if (deleteId === null) return
      this.orders = this.orders.filter((order: Order) => {
        return (order.id !== deleteId)
      })
    },
    addOrder () {
      if (this.code.aConnector === '' ||
          this.code.aPolishing === '' ||
          this.code.mode === '' ||
          this.code.lineCount === '' ||
          this.code.outerDiameter === '' ||
          this.code.length === ''
      ) {
        this.errorMessage = '必須項目が入力されていません。'
        this.dialog_error = true
        return
      }
      this.orders.push({
        id: this.orders.length + 1,
        aConnector: this.code.aConnector,
        aPolishing: this.code.aPolishing,
        bConnector: this.code.bConnector,
        bPolishing: this.code.bPolishing,
        mode: this.code.mode,
        lineCount: this.code.lineCount,
        outerDiameter: this.code.outerDiameter,
        length: this.code.length,
        others: this.code.others.join(' '),
        orderValue: 1
      })
    },
    async postForm () {
      try {
        if (this.orders.length === 0) return
        if (!this.form.validate()) {
          this.errorMessage = '必須項目が入力されていません。'
          this.dialog_error = true
        } else {
          let freeWord = ''
          this.orders.forEach(function (order: Order) {
            freeWord += (order.aConnector +
            ':' + order.aPolishing +
            ':' + order.bConnector +
            ':' + order.bPolishing +
            ':' + order.mode +
            ':' + order.lineCount +
            ':' + order.outerDiameter +
            ':' + order.length +
            ':' + order.orderValue +
            '\n')
          })
          this.orderForm.parts += freeWord
          const response = await axios.post('/v1/opt_search_send.php', this.orderForm)

          if (response.data.message) {
            this.message = response.data.message
            this.dialog = true
            return
          }
        }
      } catch (e) {
        console.log('フォームの送信に失敗：', this.orderForm)
        console.error(e)
      }
    },
    sendReset () {
      this.dialog = false
      router.push('/opt_custom_order')
    }
  }
})
